import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Form, Input, InputNumber, Button, notification, Modal, Checkbox, Row, Col } from "antd";
import { get } from "lodash";

import withCrowdfunding from "../../containers/withCrowdfunding";
import { useThemeContext } from "../Layout/ThemeContext";
import {
	donate,
	cancel,
	messageForAnonymous,
	anonymDonate,
	formMessages,
} from "../../constants";
import {
	fundValidationRules,
	getEmailValidationRules,
	getFirstNameValidationRules,
	formBWtheme,
} from "../../utils";
import { removeEmptyKeys } from "../../helpers";

function Donate({ crowdfundingProjectId, isOpen, handleClose, updateCurrentOrder, form }) {
	const [loading, setLoading] = useState(false);
	const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
	const [anonymous, setAnonymous] = useState(true);
	const { theme } = useThemeContext();

	function pay(paymentData = null) {
		if (typeof cp !== "undefined" && paymentData) {
			// eslint-disable-next-line no-undef
			const widget = new cp.CloudPayments({ language: "ru" });

			widget.charge(
				Object.assign({}, paymentData, {
					skin: "mini",
				}),
				() => {
					Modal.success({
						title: "Пожертвование отправлено",
						content:
							"Всещедрый Господь да вознаградит Ваши праведные труды и сохранит на многая и благая лета! ",
					});
					setLoading(false);
					form.setFieldsValue({
						firstName: "",
						email: "",
					});
					handleClose();
				},
				() => {
					notification.error({
						message: "Платёж отменён",
					});
					setLoading(false);
				},
			);
		} else {
			notification.error({
				message: "Ошибка загрузки платёжного виджета",
			});
			setLoading(false);
		}
	}

	function handleSubmit(event) {
		event.preventDefault();

		setLoading(true);

		form.validateFields(async (err, values) => {
			if (!err) {
				try {
					const updatedOrder = await updateCurrentOrder({ variables: values });
					const paymentData = get(updatedOrder, "data.updateCurrentOrder.order.paymentData", null);

					pay(paymentData);
				} catch {
					notification.error({
						message: "Ошибка загрузки текущего задания",
					});
					setLoading(false);
				}
			} else {
				notification.error({
					message: "Поля формы заполнены некорректно",
				});
				setLoading(false);
			}
		});
	}

	const updateAmountField = useCallback(summ => {
		setFieldsValue({
			amount: getFieldValue("amount") + summ,
		});
	}, []);

	getFieldDecorator("crowdfundingProjectId", {
		initialValue: crowdfundingProjectId,
	});

	return (
		<>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<Modal
				title={"Помощь монастырю"}
				visible={isOpen}
				onCancel={handleClose}
				width={500}
				style={removeEmptyKeys({ backgroundColor: theme.color.body })}
				footer={[
					<Row key={"submit-group"} className="gutter-row">
						<Col span={12} className={"pr-2"}>
							<Button
								block
								type="primary"
								onClick={handleClose}
								disabled={loading}
								style={removeEmptyKeys({
									backgroundColor: theme.color.text,
									color: theme.color.body,
								})}
							>
								{cancel}
							</Button>
						</Col>
						<Col span={12} className={"pl-2"}>
							<Button
								block
								type="primary"
								onClick={handleSubmit}
								loading={loading}
								style={removeEmptyKeys({
									backgroundColor: theme.color.text,
									color: theme.color.body,
								})}
							>
								{donate}
							</Button>
						</Col>
					</Row>,
				]}
			>
				<Form layout={"vertical"} onSubmit={handleSubmit}>
					<Form.Item>{messageForAnonymous}</Form.Item>
					<Form.Item>
						{getFieldDecorator("anonymous", {
							initialValue: true,
							rules: getFirstNameValidationRules(!anonymous),
						})(
							<Checkbox checked={anonymous} onChange={event => setAnonymous(event.target.checked)}>
								{anonymDonate}
							</Checkbox>,
						)}
					</Form.Item>
					{formBWtheme(anonymous, theme.color.text)}
					<Form.Item>
						{getFieldDecorator("V", {
							initialValue: "",
							rules: getFirstNameValidationRules(!anonymous),
						})(<Input placeholder={formMessages.firstName.placeholder} disabled={anonymous} />)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator("email", {
							initialValue: "",
							rules: getEmailValidationRules(!anonymous),
						})(<Input placeholder={formMessages.email.placeholder} type={"email"} disabled={anonymous} />)}
					</Form.Item>
					<Input.Group compact>
						{getFieldDecorator("amount", {
							initialValue: 1000,
							rules: fundValidationRules,
						})(<InputNumber prefix={"₽"} min={0} step={100} htmlType={"number"} placeholder={formMessages.fund.placeholder} />)}
						<Button.Group>
							<Button
								onClick={() => updateAmountField(100)}
							>
							+100
							</Button>
							<Button
								onClick={() => updateAmountField(500)}
							>
							+500
							</Button>
							<Button
								onClick={() => updateAmountField(1000)}
							>
							+1000
							</Button>
						</Button.Group>
					</Input.Group>
				</Form>
			</Modal>
		</>
	);
}

export default withCrowdfunding(Form.create({ name: "donate" })(Donate));

Donate.propTypes = {
	crowdfundingProjectId: PropTypes.number,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	updateCurrentOrder: PropTypes.func,
	form: PropTypes.object,
	getFieldDecorator: PropTypes.func,
};

Donate.defaultProps = {
	crowdfundingProjectId: null,
	isOpen: false,
	handleClose: () => {},
	updateCurrentOrder: () => {},
	form: {},
	getFieldDecorator: () => {},
};
