import React, { useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { Button } from "antd";
import { isMobile } from "react-device-detect";

import { TextBlock, AppealBlock, ContentBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Banner from "../../components/Banner";
import { PureCarousel } from "../../components/PureReactCarousel";
import Pages from "../../components/Pages";
import MapComponent from "../../components/Map";
import Collapse from "../../components/Collapse";
import CrowdfundingBlock from "../../components/Crowdfunding/CrowdfundingBlock";
import Trebs from "../../components/Trebs";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import Donate from "../../components/Donate";
import { Share, Weather } from "../../widgets";
import Contacts from "../../components/Contacts";
import {
	MetaImage,
	MetaPublicAccess,
	MetaName,
	MetaTelephone,
	MetaAddress,
	MetaOpeningHours,
	MetaGeo,
} from "../../components/Meta";
import { donate } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import {
	makeUrl,
	sorter,
	makeNews,
	makeArticles,
	makeHolyPlaces,
	makeBooks,
	makeMediaData,
	markdownConverter,
	makeShrines,
	removePreloader,
	getPoint,
	makeVirtualTourSlides,
	removeEmptyKeys,
} from "../../helpers";
import { MONASTERY_DATA, MONASTERY_MAX_UPDATE } from "../../queries/queries.graphql";

export const query = graphql`
	query monasteriesDataQuery($slug: String!) {
		hasura {
			...MonasteryContent
			...HolyPlaces
			...CrowdfundingProjects
		}
	}
`;

export default function MonasteryPage({ data, pageContext }) {
	const { theme } = useThemeContext();

	const [monastery, setMonastery] = useState(get(data, "hasura.monasteries[0]", {}));
	const [donateIsOpen, setDonateIsOpen] = useState(false);

	const maxUpdated = new Date(get(data, "hasura.monasteries_aggregate.aggregate.max.updated_at", new Date()));
	const { slug } = pageContext;

	const monasteruOptions = {
		fetchPolicy: "no-cache",
		variables: {
			slug,
		},
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(MONASTERY_MAX_UPDATE, monasteruOptions);
	const [loadMOnastery, { loading: dataLoading, called, data: fetchData, error: dataError }] = useLazyQuery(MONASTERY_DATA, monasteruOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "monasteries_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadMOnastery();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load MonasteryMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (called && fetchData && !dataError) {
			setMonastery(get(fetchData, "monasteries[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const ids = get(monastery, "crowdfunding_projects", [])
		.filter(project => get(project, "crowdfunding_project.kind", "") === "crowdfunding")
		.map(project => get(project, "crowdfunding_project.id", ""));

	const crowdfunding = get(data, "hasura.crowdfundingProjects", []).filter(project => ids.includes(+project.id));

	const bannerSlides = [
		{
			title: get(monastery, "page_title_full", ""),
			publicURL: get(monastery, "main_image.src"),
			mainImageMobile: get(monastery, "main_image_mobile.src", ""),
		},
	];

	const holyPlaces = makeHolyPlaces(get(data, "hasura.v_content_holy_places", []));

	const cathedrals = get(monastery, "cathedrals", []);
	const wells = get(monastery, "wells", []);
	const shrines = makeShrines(get(monastery, "shrines", []).sort((a, b) => sorter(a.sort_order, b.sort_order)));
	const hermitages = get(monastery, "hermitages", []);
	const temples = get(monastery, "temples", []);
	const churches = get(monastery, "churches", []);
	const belltowers = get(monastery, "belltowers", []);
	const mapItems = [monastery, ...cathedrals, ...wells, ...shrines, ...hermitages, ...temples, ...churches, ...belltowers];
	const weather = get(monastery, "city.weather", {});
	const virtualTour = makeVirtualTourSlides(get(monastery, "monasteries_virtual_tours", []));

	const {
		main_image,
		main_image_preview,
		title_full,
		telephone,
		openning_hours,
		address,
		page_title_short,
		page_title_full,
	} = monastery;

	const contentBlocks = get(monastery, "content_blocks", []);

	const appeal_to_visitors = get(monastery, "appeal_to_visitors", {});

	const coordinates = get(getPoint(monastery), "[0].geometry.coordinates", []);

	const photos = makeMediaData(get(monastery, "monasteries_media_photos", []), "photos");

	const allMedia = photos.sort((a, b) => sorter(a.sort_order, b.sort_order));

	const periodics = makeMediaData(
		get(monastery, "monasteries_media_periodics", []).map(item => item.media_periodic),
		"periodics",
	);
	const books = makeBooks(get(monastery, "books", []));
	const mediaBooks = makeBooks(get(monastery, "monasteries_media_books", []).map(item => item.media_book));
	const allBooks = [...periodics, ...books, ...mediaBooks].sort((a, b) => sorter(a.sort_order, b.sort_order));

	const articles = makeArticles(get(monastery, "monasteries_media_articles", []).map(item => item.media_article));

	const donateProjects = get(monastery, "crowdfunding_projects", [])
		.filter(e => get(e, "crowdfunding_project.kind", "") === "donate")
		.map(e => get(e, "crowdfunding_project.id", ""));

	const url = makeUrl.monastery(monastery);

	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));

	const news = makeNews(get(monastery, "monasteries_news", []).map(item => item.news));

	// Page blocks
	const banner = useMemo(
		() => (
			<ContentBlock key={"banner"}>
				<Banner slides={bannerSlides}>
					{isMobile && <Weather weather={weather} />}
				</Banner>
				{/* // TODO: Use ContentBlock component with container or container_fluid */}
			</ContentBlock>
		),
		[bannerSlides, weather],
	);

	const breadcrumbs = useMemo(
		() => (
			<ContentBlock key={"breadcrumbs"}>
				<div className={"container d-flex justify-content-between"}>
					<Breadcrumbs currentLabel={title_full} pageContext={pageContext} />
					{!isMobile && <Weather weather={weather} />}
				</div>
			</ContentBlock>
		),
		[weather],
	);

	const countDown = <ContentBlock id={"translation"} className={"translation d-none"} key={"broadcast"}>
		<div className={"container"}>
			{/* <Broadcast
					slug={path}
					query={BROADCAST_MONASTERY}
					prefix={"monasteries"}
				/> */}
		</div>
	</ContentBlock>;

	const appeal = useMemo(
		() => {
			const body = get(appeal_to_visitors, "body", "");

			return (
				<ContentBlock key={"appeal"} mount={appeal_to_visitors && !!Object.keys(appeal_to_visitors).length}>
					<div className={"container"}>
						<TextBlock title={get(appeal_to_visitors, "title", "")}>
							<AppealBlock
								img={get(appeal_to_visitors, "image.src.src", "")}
								alt={get(appeal_to_visitors, "title", "")}
								fullWidth ={!body}
							>
								{markdownConverter(body)}
							</AppealBlock>
						</TextBlock>
					</div>
				</ContentBlock>
			);
		},
		[appeal_to_visitors],
	);

	const mediaContent = useMemo(
		() => (
			<ContentBlock key={"mediaContent"} mount={contentBlocks && !!contentBlocks.length}>
				<div className={"container"}>
					<Collapse contentBlocks={contentBlocks && contentBlocks}>
						<Contents items={contentBlocks} />
					</Collapse>
				</div>
			</ContentBlock>
		),
		[contentBlocks],
	);

	const holyPlacesBlock = useMemo(
		() => (
			<ContentBlock key={"holyPlaces"} mount={holyPlaces && !!holyPlaces.length}>
				<div className={"container"}>
					<TextBlock
						title={"Святые места"}
						list={"Смотреть всё"}
						listLink={makeUrl.holyPlaces(pageContext)}
						allowBtn={holyPlaces && holyPlaces.length > 5}
					>
						<PureCarousel
							type={"SlideLinks"}
							items={holyPlaces}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 3,
									},
									{
										width: 1136,
										slidesPerView: 4,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[holyPlaces],
	);

	const calendar = (
		<ContentBlock key={"calendar"} className={"d-none"} style={{ backgroundColor: "#988347" }}>
			<div className={"container"}>
				<TextBlock title={"Православный календарь"}>{/* <DayCalendar /> */}</TextBlock>
			</div>
		</ContentBlock>
	);

	const newsBlock = useMemo(
		() => (
			<ContentBlock key={"news"} mount={news && !!news.length}>
				<div className={"container"}>
					<TextBlock title={"Новости"} list={"Смотреть всё"} listLink={makeUrl.contentNews(pageContext)} allowBtn={news && news.length > 5}>
						<PureCarousel
							type={"SlideNews"}
							items={news}
							mediaSize={["100%", 400]}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 768,
										slidesPerView: 2,
									},
									{
										width: 950,
										slidesPerView: 3,
									},
								],
								btnStyle: {
									top: "40%",
								},
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[news],
	);

	const donateBlock = useMemo(
		() => (
			<ContentBlock key={"donate"} className={"bg-main"} mount={donateProjects && !!donateProjects.length}>
				<div className="container">
					<TextBlock
						title={"Помощь монастырю"}
						desc={
							"Просим откликнуться всех неравнодушных и внести свой посильный вклад в дело благоукрашения, реставрации и строительства объектов монастыря! Благодаря вашей	помощи могут быть воссозданы храмы, реконструированы монастырские трапезные, келейные корпуса и гостиницы, а также построены уникальные объекты архитектуры."
						}
					>
						<div className="row">
							<div className="col-12">
								<Button
									onClick={() => setDonateIsOpen(true)}
									htmlType={"submit"}
									className={"button-white"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.body,
										backgroundColor: theme.color.text,
									})}
								>
									{donate}
								</Button>
								<Donate
									crowdfundingProjectId={donateProjects[0]}
									isOpen={donateIsOpen}
									handleClose={() => setDonateIsOpen(false)}
								/>
							</div>
						</div>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[donateProjects, donateIsOpen],
	);

	const articlesBlock = useMemo(
		() => (
			<ContentBlock key={"articles"} mount={articles && !!articles.length}>
				<div className={"container"}>
					<TextBlock title={"Статьи"}>
						<PureCarousel
							type={"SlideNews"}
							items={articles}
							mediaSize={["100%", 400]}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 5.5,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 3,
									},
									{
										width: 1136,
										slidesPerView: 4,
									},
								],
								btnStyle: {
									top: "40%",
								},
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[articles],
	);

	const shrinesBlock = useMemo(
		() => (
			<ContentBlock key={"shrines"} mount={shrines && !!shrines.length}>
				<div className={"container"}>
					<TextBlock title={"Святыни"}
						list={"Смотреть всё"}
						listLink={makeUrl.contentShrines(pageContext)}
						allowBtn={shrines && shrines.length > 5}
					>
						<PureCarousel
							type={"SlideNews"}
							items={shrines}
							mediaSize={["100%", 400]}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 5.5,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 3,
									},
									{
										width: 1136,
										slidesPerView: 4,
									},
								],
								btnStyle: {
									top: "40%",
								},
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[shrines],
	);

	const mediaBlock = useMemo(
		() => (
			<ContentBlock key={"allMedia"} mount={allMedia && !!allMedia.length}>
				<div className={"container"}>
					<TextBlock title={"Медиа"}>
						<PureCarousel
							type={"SlideLinks"}
							items={allMedia}
							mediaSize={["100%", 400]}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 3,
									},
									{
										width: 1136,
										slidesPerView: 4,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[allMedia],
	);

	const trebsBlock = useMemo(() => (
		<ContentBlock key={"trebs"} className={"bg-accent"} mount={!/diveevskiy/.test(monastery.slug)}>
			<div className={"container"}>
				<TextBlock
					title={"Записки о поминовении"}
					desc={
						"Дорогие братия и сестры! Здесь Вы можете подать записки о здравии и об упокоении близких в монастырях Арзамаса и Сарова. В записках указываются только имена крещеных в Православной церкви. Имена некрещеных и самоубийц в записках не пишутся. Ни одна Ваша просьба о поминовении не останется незамеченной. Все средства от пожертвований направляются напрямую монастырям."
					}
				>
					<Trebs
						name={monastery.title_short || monastery.title_full}
						project={monastery.title_short || monastery.title_full}
					/>
				</TextBlock>
			</div>
		</ContentBlock>
	));

	const mapBlock = useMemo(
		() => (
			<ContentBlock key={"mainMap"} mount={mapItems && !!mapItems.length}>
				<div className={"container"}>
					<TextBlock title={"Расположение"} />
				</div>
				<MapComponent mapId={"MonasteryMap"} items={mapItems} showPoligon />
			</ContentBlock>
		),
		[mapItems],
	);

	const crowdfundingBlock = useMemo(
		() => (
			<ContentBlock key={"crowdfunding"} mount={crowdfunding && !!crowdfunding.length}>
				<div className={"container"}>
					<TextBlock title={"Поддержать проект"}>
						<CrowdfundingBlock data={crowdfunding} />
					</TextBlock>
				</div>
			</ContentBlock>
		),
		crowdfunding,
	);

	const booksBlock = useMemo(
		() => (
			<ContentBlock key={"books"} className={"d-none"} mount={allBooks && !!allBooks.length}>
				<div className={"container"}>
					<TextBlock title={"Книги и журналы"}>
						<PureCarousel
							type={"SlideContent"}
							items={allBooks}
							mediaSize={["100%", 400]}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 3,
									},
									{
										width: 1136,
										slidesPerView: 4,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[allBooks],
	);

	const contactsBlock = useMemo(
		() => (
			<ContentBlock mount={monastery && address || !!openning_hours || !!telephone} key={"contacts"}>
				<div className={"container"}>
					<div className={"row"}>
						<div className={"col-12"}>
							<TextBlock title={"Контакты"} className={"pb-2"} />
						</div>
					</div>
					<Contacts address={address} hours={openning_hours} telephone={telephone} />
				</div>
			</ContentBlock>
		),
		[monastery],
	);

	const virtualTourBlock = useMemo(
		() => (
			<ContentBlock key={"virtual_tours"} mount={virtualTour && !!virtualTour.length}>
				<div className={"container"} >
					<TextBlock title={"Виртуальные экскурсии"} id={"Virtual-Tours"}>
						<PureCarousel
							type={"SlideLinks"}
							items={virtualTour}
							slideRatio={{
								naturalSlideWidth: 2,
								naturalSlideHeight: 1,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 1,
								naturalSlideHeight: 0.8,
							}}
							params={{
								slidesPerView: 2,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 2,
									},
									{
										width: 1136,
										slidesPerView: 2,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[virtualTour],
	);

	const pageBody = {
		banner,
		countDown,
		breadcrumbs,
		appeal,
		mediaContent,
		holyPlacesBlock,
		calendar,
		newsBlock,
		donateBlock,
		articlesBlock,
		shrinesBlock,
		mediaBlock,
		trebsBlock,
		mapBlock,
		crowdfundingBlock,
		booksBlock,
		contactsBlock,
		virtualTourBlock,
	};

	const blocksDirection = {
		"default": [
			"banner",
			"breadcrumbs",
			"countDown",
			"appeal",
			"mediaContent",
			"holyPlacesBlock",
			"shrinesBlock",
			"calendar",
			"newsBlock",
			"donateBlock",
			"articlesBlock",
			"mediaBlock",
			"trebsBlock",
			"virtualTourBlock",
			"contactsBlock",
			"mapBlock",
			"crowdfundingBlock",
			"booksBlock",
		],
	};

	return (
		<Pages entity={monastery} url={url} >
			<div itemScope itemType="https://schema.org/Church">
				<MetaPublicAccess content="true" />
				<MetaGeo content={coordinates} />
				<MetaImage content={imageSrc} />
				<MetaName content={title_full} />
				<MetaTelephone content={telephone} />
				<MetaOpeningHours content={openning_hours} />
				<MetaAddress content={address} />
				{blocksDirection.default.map(block => pageBody[block] || null)}
				<Share
					url={url}
					pageTitleShort={page_title_short}
					pageTitleFull={page_title_full}
					imageSrc={imageSrc}
				/>
			</div>
		</Pages>
	);
}

MonasteryPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

MonasteryPage.defaultProps = {
	data: {},
	location: {},
	pageContext: {},
};
